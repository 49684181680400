import { useEffect, useRef, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useMap, Marker, Popup, Circle } from 'react-leaflet';
import MainMap from '../components/MainMap';
import { bearing, distance, medianLatLng } from '../map/math';
import getColor from '../common/colors';
import L, { marker } from 'leaflet';

export default function Map() {
    const { layout, setLayout } = useOutletContext();
    const map = useRef(null);

    useEffect(() => { layout.title === null && setLayout.title("Flickrmap") }, [layout, setLayout]);

    const [markers, setMarkers] = useState([{ lat: 52, lng: 4 }, { lat: 52, lng: 94 }]);
    const [median, setMedian] = useState(medianLatLng(markers));

    const updateMedian = function (id, marker) {
        const newMedian = medianLatLng(markers);
        const newLatLng = marker.getLatLng();
        markers[id] = { ...newLatLng, bearing: bearing(newMedian, newLatLng), distance: distance(newMedian, newLatLng) };
        setMarkers(markers);
        setMedian(newMedian);
        // console.log(markers[id]);
    };

    const redIcon = L.divIcon({
        className: 'custom-icon',
        html: '<div style="background-color: ' + getColor('red') + '; width: 20px; height: 20px; border-radius: 50%;"></div>',
        iconSize: [20, 20],
    });

    const blueIcon = L.divIcon({
        className: 'custom-icon',
        html: '<div style="background-color: ' + getColor('blue') + '; width: 20px; height: 20px; border-radius: 50%;"></div>',
        iconSize: [20, 20],
    });

    const lightIcon = L.divIcon({
        className: 'custom-icon',
        html: '<div style="background-color: ' + getColor('aqua') + '; width: 20px; height: 20px; border-radius: 50%;"></div>',
        iconSize: [20, 20],
    });

    useEffect(() => {
        if (map.current)
        markers.map((latLng, id) => L.Marker({ position: latLng, id }).addTo(map));
    }, [map]);

    return (
            <MainMap ref={map}>
                
                <Marker position={median} icon={redIcon} />
            </MainMap>
    );
}