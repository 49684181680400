import { MapContainer, TileLayer } from 'react-leaflet';

export default function MainMap({ children, ...properties }) {
    return (
        <MapContainer className="map" center={[52.017, 4.7094]} zoom={6} {...properties} continuousWorld={true}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {children}
        </MapContainer>
    );
}