import { useEffect, useState } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import DataLoader from '../components/DataLoader';

export default function TempPhotos() {
    const { layout, setLayout } = useOutletContext();
    useEffect(() => { layout.title === null && setLayout.title("Ruben Holthuijsen") }, [layout, setLayout]);
    const [photos, setPhotos] = useState(null);

    return (
        <DataLoader action="temp-photos.php?json" setData={setPhotos}>
            <div className="ph10">
                <p>
                    <b>NEW! <a href="https://deviantart.com/rubenholt" target="_blank">Click here to see artistic reinterpretations of my photos on DeviantArt</a></b>
                </p>
                <p>
                    <a href="https://flickr.com/photos/rubenholthuijsen" target="_blank">Click here to visit my Flickr page</a>
                    {' or '}
                    <Link to="flickrmap">click here to see my photos on a map</Link>.
                </p>
                <p>Here are some semi-random photos of mine:</p>
                <div className="temp-photos">
                    {photos?.map((photo) => (
                    <a key={photo.id} href={"/click/?photo="+photo.id} target="_blank" title={photo.title}>
                        <figure style={{ backgroundImage: "url(" + photo.image + ")" }}></figure>
                    </a>
                    ))}
                </div>
                <p>Also, I've found that some of my photos have been uploaded to <a href="https://commons.wikimedia.org/wiki/User:RubenHolt" target="_blank">Wikimedia Commons</a>.</p>
            </div>
        </DataLoader>
    );
}