const colors = {
    red: '#FF3333',
    green: '#00CC00',
    yellow: '#FFDD00',
    blue: '#4477FF',
    aqua: '#33DDFF',
    cyan: '#33DDFF',
    lightblue: '#33DDFF',
    pink: '#CC00FF',
    fuchsia: '#CC00FF',
    orange: '#FF8800',
    lime: '#88EE33',
    purple: '#8800FF',
    maroon: '#883300',
    navy: '#3300DD',
    teal: '#00CCCC',
    silver: '#DDDDDD',
    lightgrey: '#DDDDDD',
    lightgray: '#DDDDDD',
    grey: '#444444',
    gray: '#444444',
};

export default function getColor(n) {
    return n in colors ? colors[n] : n;
}