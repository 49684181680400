import Breadcrumbs from "./Breadcrumbs";
import CircleThumb from './CircleThumb';
import SearchAndFilter from './SearchAndFilter';

export default function PageTitle({ layout, setLayout }) {
    
    return (
        <div className="flex">
            { layout.thumb && <CircleThumb src={layout.thumb} className="m10" /> }
            <div className="flex-grow">
                <h1>
                    { layout.title }
                    { layout.subtitle && <span className="unbold">{' '}({layout.subtitle})</span> }
                </h1>
                { layout.breadcrumbs && layout.breadcrumbs.length > 0 && <Breadcrumbs crumbs={layout.breadcrumbs} /> }
            </div>
            { layout.hasSearch && <SearchAndFilter
                query={layout.search}
                setQuery={setLayout.search}
                sort={layout.sort}
                setSort={setLayout.sort}
                sortOptions={layout.sortOptions}
            /> }
        </div>
    );

}