import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import packageJson from '../package.json';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Root from "./Root";
import TempPhotos from "./routes/TempPhotos";
import Locations from "./routes/Locations";
import Map from "./routes/Map";
import Redirect from './routes/Redirect';
import Photos from './routes/Photos';
import Trips from './routes/Trips';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root version={(packageJson.version ?? '') + (process.env.NODE_ENV === 'development' ? '-dev' : '')} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <TempPhotos />,
      },
      {
        path: "locations/*",
        element: <Locations />,
      },
      {
        path: "photos",
        element: <Photos />,
      },
      {
        path: "photos/:page",
        element: <Photos />,
      },
      {
        path: "trips",
        element: <Trips />,
      },
      {
        path: "trips/:year",
        element: <Trips />,
      },
      {
        path: "trips/:year/*",
        element: <Trips />,
      },
      {
        path: "map",
        element: <Map />,
      },
      {
        path: "flickrmap",
        element: <Redirect to="flickrmap" />,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);
