import ChangableInput from "./ChangableInput";

export default function SearchAndFilter({ query, setQuery, sort, setSort, sortOptions, placeholder = 'Search' }) {
    
    return (
        <>
            <div className="search-box">
                <ChangableInput className="form-control" value={query} setValue={setQuery} placeholder={placeholder} />
                { sortOptions?.length > 0 && <select className="form-control light" onChange={ event => setSort(event.target.value) } value={ sort }>
                    { sortOptions.map(option => (
                        <option key={ option.handle } value={ option.handle }>{ option.title }</option>
                    )) }
                </select> }
            </div>
        </>
    );
}