export function lat(latLng) {
    // Get latitude from latLng object: [0, 1] or { lat: 0, lng: 1 }
    return +(latLng?.lat ?? latLng[0]) ?? null;
}


export function lng(latLng) {
    // Get longitude from latLng object: [0, 1] or { lat: 0, lng: 1 }
    return +(latLng?.lng ?? latLng[1]) ?? null;
}


export function mod(value, modulus) {
    // Proper modulo function that respects positive/negative values and moduli (return value is always between 0 and modulus, positive or negative)
    return (value % modulus) + ((value < 0) !== (modulus < 0)) * modulus;
}


export function median(values) {
    // Get median from a sorted list of values
    return values?.length > 0 ? (values[Math.floor((values.length - 1) / 2)] + values[Math.ceil((values.length - 1) / 2)]) / 2 : null;
}


export function spread(values) {
    // Get spread (max - min) from a sorted list of values
    return values?.length > 0 ? (values[values.length - 1] - values[0]) : null;
}


export function medianLatLng(latLngs) {
    // Get median point from array of latLngs
    
    // Create sorted lists of all latitudes and longitudes
    const lats = latLngs.map(lat).sort();
    const lngs = latLngs.map(lng).sort();
    
    // Try alternative longitudes from 0 to 360 instead of -180/+180
    const zeroIndex = lngs.findIndex(n => n >= 0);
    const lngsAlt = zeroIndex > 0 ? lngs.slice(zeroIndex).concat(lngs.slice(0, zeroIndex).map(n => n + 360)) : lngs;
    const altMedian = median(lngsAlt) > 180 ? median(lngsAlt) - 360 : median(lngsAlt);

    return { lat: median(lats), lng: spread(lngs) < spread(lngsAlt) ? median(lngs) : altMedian };
}


export function deg2rad(deg) {
    // Degrees to radians
    return deg * (Math.PI / 180);
}


export function rad2deg(rad) {
    // Radians to degrees
    return rad * (180 / Math.PI);
}


export function distance(latLng1, latLng2) {
    // Distance calculation from latLng1 to latLng2
    
    const R = 6371; // Earth's radius in kilometers
    const dLat = deg2rad(lat(latLng2) - lat(latLng1));
    const dLng = deg2rad(lng(latLng2) - lng(latLng1));

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.cos(deg2rad(lat(latLng1))) * Math.cos(deg2rad(lat(latLng2))) *
              Math.sin(dLng / 2) * Math.sin(dLng / 2);
              
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    
    return R * c; // distance in kilometers
}


export function bearing(latLng1, latLng2) {
    // Bearing calculation (in degrees) from latLng1 to latLng2
    
    const y = Math.sin(deg2rad(lng(latLng2) - lng(latLng1))) * Math.cos(deg2rad(latLng2.lat));
    const x = Math.cos(deg2rad(lat(latLng1))) * Math.sin(deg2rad(lat(latLng2))) -
              Math.sin(deg2rad(lat(latLng1))) * Math.cos(deg2rad(lat(latLng2))) * Math.cos(deg2rad(lng(latLng2) - lng(latLng1)));
    
    return (rad2deg(Math.atan2(y, x)) + 360) % 360;
}