import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import PhotoGrid from '../components/PhotoGrid';

export default function Photos() {
    const { layout, setLayout } = useOutletContext();
    const params = useParams();
    const [page, setPage] = useState(params?.page || 1);

    const pageLink = p => p > 1 ? "/photos/" + p : "/photos";

    useEffect(() => {
        if (layout.title === null) {
            setLayout.title("Photos by Ruben Holthuijsen");
            setLayout.hasSearch(true);
            setLayout.sortOptions([
                { title: 'Date', handle: 'datetime' },
                { title: 'Title', handle: 'title' },
                { title: 'Views', handle: 'views' },
                { title: 'Faves', handle: 'faves' },
            ])
        }
    }, [layout, setLayout]);

    useEffect(() => {
        if (page !== (params?.page || 1)) {
            // Catch Back button clicks
            setPage(params?.page || 1);
        }
    }, [page, params]);

    useEffect(() => {
        if (page !== 1 && layout.search !== null) {
            // Always go to page 1 when search query changes
            updatePage(1);
        }
        // ESlint complains about missing "page" and "updatePage" even though that doesn't make sense
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layout.search]);

    const updatePage = p => {
        // Manually changed page or received different page number
        params.page = p;
        setPage(p);
        window.history.pushState({}, '', pageLink(p));
    };

    return (
        <PhotoGrid page={page} perPage={30} search={layout.search} setPage={updatePage} pageLink={pageLink} sort={layout.sort} />
    );
}