import { useState } from 'react';
import DataLoader from './DataLoader';
import PhotoDescription from './PhotoDescription';
import Pagination from './Pagination';

export default function PhotoGrid({
    page = null,
    perPage = null,
    locationId = null,
    locationOnly = null,
    tripId = null,
    search = null,
    sort = null,
    showDescriptions = true,
    setPhotoCount = null,
    setPage = null,
    pageLink = null,
}) {
    const [data, setData] = useState(null);

    const updateData = data => {
        setData(data);
        typeof setPhotoCount === 'function' && setPhotoCount(+data.count);
        typeof setPage === 'function' && +page !== +data?.page && setPage(+data.page);
    };

    const [pageFallback, setPageFallback] = useState(page || 1);

    if (setPage === null) {
        page = pageFallback;
        setPage = setPageFallback;
    }

    return (
        <DataLoader
            action="new/flickrmap/photos"
            params={{ p: page, pp: perPage, l: locationId, only: locationOnly ? 1 : null, t: tripId, q: search, "e[s]": 1, sort }}
            setData={updateData}
            dependencies={[search, page, sort]}
        >
            <div className="flex-vert flex-grow">
                <section className="edit-photos pos-rel">
                    <div className="p5 pos-abs-wide scroll-y-wide">
                        <div className="edit-photos-container">
                            { data?.photos?.map(photo => (
                                <a className="edit-photo-image button white" key={photo.id} href={photo.link} title={photo.title} alt="" target="_blank">
                                    <div>
                                        <img src={photo.image.replace(/_m\.jpg$/, ".jpg")} alt={photo.id} />
                                        { showDescriptions && <PhotoDescription photo={photo} /> }
                                    </div>
                                </a>
                            )) }
                        </div>
                    </div>
                </section>
                { (data?.pages > 1 || data?.page > 1) && (
                    <div className="top-border">
                        <Pagination page={data?.page} pageCount={data?.pages} setPage={setPage} pageLink={pageLink} />
                    </div>
                ) }
            </div>
        </DataLoader>
    );
}